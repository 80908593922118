import React, { useEffect, useState } from "react";
import Breadcrumb from "../../Layouts/Breadcrumb";
import Data from "../../utils/data";
import Api from "../../utils/api";
import ClipLoader from "react-spinners/ClipLoader";
import { Link, useHistory, withRouter } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import InfiniteScroll from "react-infinite-scroller";
import LoadingImage from "../../../assets/img/loading.gif";
import InputRange from "react-input-range";
import "react-input-range/lib/css/index.css";
import MetaTags from "react-meta-tags";
import AddSlider from "../../page/Product/CategorySlider/AddSlider";
import ListProduct from "../../page/Product/ListProduct/ListProduct";
const LayoutViewAll = (props) => {
  let history = useHistory();
  const [layout, setLayout] = useState({});
  const [appliedFilter, setAppliedFilter] = useState([]);
  const [brands, setBrands] = useState([]);
  const [products, setProducts] = useState([]);
  const [priceRange, setPriceRange] = useState([]);
  const [priceRangeChange, setPriceRangeChange] = useState([]);
  const [sliders, setSliders] = useState([]);
  const [productPaginate, setProductPaginate] = useState({});
  const [nextHref, setNextHref] = useState("");
  const [hasMoreItems, setHasMoreItems] = useState(true);
  const [isLoading, setIsloading] = useState(true);
  function fetchLayout() {
    let params = fetchParams();
    let url = new URL(Data.API_BASE_URL + "layouts/" + getLayoutId());
    console.log(getLayoutId());
    url.search = new URLSearchParams(params).toString();
    fetch(url, {
      method: "GET",
    })
      .then(Api.handleResponseAndError)
      .then((response) => {
        setHasMoreItems(true);
        setNextHref("");
        setLayoutData(response.data);
      })
      .catch((error) => {
        console.log(error);
        // history.push("/page-not-found");
      });
  }
  function getLayoutId() {
    let id = props.match.params["layout_name"].split("-");
    id = id[id.length - 1];
    return id;
  }
  function fetchParams(page = null) {
    let params = {};
    let brand = new URLSearchParams(props.location.search).get("brand");
    let range = new URLSearchParams(props.location.search).get("range");
    let sort = new URLSearchParams(props.location.search).get("sort");
    if (brand) {
      params.brand = brand;
    }
    if (page == 1)
      if (nextHref) {
        params.page = new URLSearchParams(new URL(nextHref).search).get("page");
      }
    if (range) {
      params.range = range;
    }
    if (sort) {
      params.sort = sort;
    }
    return params;
  }
  function checkBrand(brand) {
    let urlBrand = new URLSearchParams(props.location.search).get("brand");
    if (urlBrand) {
      let brands = urlBrand.split("---");
      var findBrand = brands.find(function (brandfind) {
        return brandfind == brand.name;
      });
      if (findBrand) {
        return true;
      } else {
        return false;
      }
    }
  }
  function setLayoutData(data) {
    setLayout(data["layout"]);
    setProducts([]);
    setBrands(data["brands"]);
    setProductPaginate(data["productPaginate"]);
    setPriceRange(data["priceRange"]);
    let range = new URLSearchParams(props.location.search).get("range");
    let appliedFilter = new URLSearchParams(props.location.search).toString();
    setAppliedFilter(appliedFilter.split("&"));
    if (range) {
      range = range.split("-");
      let newRange = {};
      newRange.min = range[0];
      newRange.max = range[1];
      setPriceRangeChange(newRange);
    } else {
      setPriceRangeChange(data["priceRange"]);
    }
    setSliders(data["sliders"]);
    setIsloading(false);
  }
  function checkFilterApply() {
    let filter = new URLSearchParams(props.location.search).toString();
    filter = filter.split("&");
    let div;
    if (filter.length > 1 || filter[0] != "") {
      filter = filter
        .filter(function (data) {
          if (data.split("=")[0] != "sort" && data.split("=")[0] != "range") {
            return true;
          } else {
            return false;
          }
        })
        .map(function (data) {
          let objectData = {};
          objectData.filter_name = data.split("=")[0];
          objectData.filter_data = data.split("=")[1];
          objectData.filter_data = objectData.filter_data.split("---");
          return objectData;
        });

      div = filter.map(function (data) {
        let divInside = data.filter_data.map(function (filter_data, index) {
          return (
            <li key={index}>
              <span className="multiselect-child">
                <a onClick={() => deleteFilterData(filter_data, data)}>
                  <span className="filter-type">
                    {" "}
                    {decodeURIComponent(filter_data)}{" "}
                    <span className="remove-filter">X</span>
                  </span>{" "}
                </a>
              </span>
            </li>
          );
        });
        return divInside;
      });
    }
    return div;
  }
  function deleteFilterData(filter_data, filter_name) {
    let filter = new URLSearchParams(props.location.search).toString();
    filter = filter.split("&");
    let search = {};
    if (filter.length > 1 || filter[0] != "") {
      filter = filter
        .filter(function (data) {
          if (data.split("=")[0] != "sort" && data.split("=")[0] != "range") {
            return true;
          } else {
            return false;
          }
        })
        .map(function (data) {
          let objectData = {};
          objectData.filter_name = data.split("=")[0];
          objectData.filter_data = data.split("=")[1];
          objectData.filter_data = objectData.filter_data.split("---");
          var findData = objectData.filter_data.indexOf(filter_data);
          if (findData !== -1) {
            objectData.filter_data.splice(findData, 1);
          }
          return objectData;
        });
    }
    var url = "?";
    filter.forEach(function (data, index) {
      if (data.filter_data.length > 0) {
        url += data.filter_name + "=" + data.filter_data.join("---") + "&";
      }
    });
    url = url.trim("&");
    history.push({
      pathname: "/view-all/" + layout.layout_url,
      search: url,
    });
  }
  function handleChange(e) {
    const item = e.target.name;
    const val = e.target.value;
    const isChecked = e.target.checked;
    let brand = new URLSearchParams(props.location.search).get("brand");

    if (brand) {
      let brands = brand.split("---");
      var findBrand = brands.find(function (brand) {
        return brand == val;
      });
      if (findBrand) {
        brands.splice(brands.indexOf(findBrand), 1);
        console.log(brands);
        history.push({
          pathname: props.match.url,
          search: "?brand=" + brands.join("---"),
        });
      } else {
        history.push({
          pathname: props.match.url,
          search: "?brand=" + brand + "---" + val,
        });
      }
    } else {
      history.push({
        pathname: props.match.url,
        search: "?brand=" + val,
      });
    }
  }
  function handlePriceChange(value) {
    let params = fetchParams();

    params.range = value.min + "-" + value.max;

    history.push({
      pathname: "/view-all/" + layout.layout_url,
      search: new URLSearchParams(params).toString(),
    });
  }
  function handleSortBy(e) {
    let params = fetchParams();
    params.sort = e.target.value;
    history.push({
      pathname: props.match.url,
      search: new URLSearchParams(params).toString(),
    });
  }
  useEffect(() => {
    setIsloading(true);
    fetchLayout();
  }, [props.match.params]);

  function loadMore() {
    let url;
    let params = fetchParams(1);
    if (nextHref) {
      url = new URL(nextHref);
    } else {
      url = new URL(Data.API_BASE_URL + "layouts/" + getLayoutId());
    }
    url.search = new URLSearchParams(params).toString();
    console.log(url);
    fetch(url, {
      method: "GET",
    })
      .then(Api.handleResponseAndError)
      .then((response) => {
        console.log(response);
        response.data.products.map(function (product) {
          products.push(product);
        });

        if (response.data.productPaginate.next_page_url) {
          setNextHref(response.data.productPaginate.next_page_url);
        } else {
          setHasMoreItems(false);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }
  const settings = {
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    lazyLoad: true,
    swipeToSlide: false,
    autoplay: true,
    fade: true,
    autoplaySpeed: 5000,
  };
  return (
    <React.Fragment>
      <div className="search-page" id="sr1">
        <div className="search-content">
          {isLoading ? (
            <div
              className="container"
              style={{ textAlign: "center", padding: "100px 0px" }}
            >
              <ClipLoader
                css={{ margin: "0 auto" }}
                size={150}
                //size={"150px"} this also works
                color={"#123abc"}
                loading={isLoading}
              />
            </div>
          ) : (
            <div className="container">
              <Breadcrumb
                levelOne={{
                  name: layout.title,
                  link: "/view-all/" + layout.title,
                }}
              />
              <MetaTags>
                <title>{layout.title}</title>
              </MetaTags>
              <div className="row search-row">
                <div className="facet-results ">
                  <div className="filter-wrp">
                    <div className="left-wrp">
                      <div className="filter-container" id="facets">
                        {appliedFilter > 1 || appliedFilter[0] != "" ? (
                          <div className="filter-type">
                            <div className="filter-section">
                              <div className="filter-name filter-accordion">
                                <div className="filter-header">
                                  <span className="cat-name">
                                    {" "}
                                    Filter{" "}
                                    <Link to={"/view-all/" + layout.layout_url}>
                                      Clear All
                                    </Link>
                                  </span>
                                  <FontAwesomeIcon
                                    className="sh-icon arrow_down"
                                    icon="angle-down"
                                  />
                                </div>
                              </div>
                              <div className="filter-collapse">
                                <div className="filter-accordion cat-layer active">
                                  <div className="saide-bar-menu">
                                    <div className="filter-section">
                                      <div className="filter-header">
                                        <div
                                          // href="#"
                                          data-attribute="taxonomies.taxonomies_hierarchical"
                                          data-value="Home Appliances"
                                          className="top-cat decoration-none active space-15 filter-data"
                                        >
                                          <ol>{checkFilterApply()}</ol>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        ) : (
                          ""
                        )}
                        <div className="filter-type">
                          <div className="filter-section">
                            <div className="filter-name filter-accordion">
                              <div className="filter-header">
                                <span className="cat-name"> Shop By </span>
                                <FontAwesomeIcon
                                  className="sh-icon arrow_down"
                                  icon="angle-down"
                                />
                                {/* <i className="sh-icon dg-chevron_down"> </i> */}
                              </div>
                            </div>
                            <div className="filter-collapse">
                              <div className="filter-accordion cat-layer active">
                                <div className="filter-section">
                                  <div className="filter-header">
                                    <a
                                      href="#"
                                      data-attribute="taxonomies.taxonomies_hierarchical"
                                      data-value="Home Appliances"
                                      className="top-cat decoration-none active space-15"
                                    >
                                      <span className="cat-name">
                                        <b>{layout.title}</b>
                                      </span>
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="filter-type checkbox-factet">
                          <div className="filter-section">
                            <div className="filter-name filter-accordion">
                              <div className="filter-header">
                                <span className="cat-name"> Brand </span>
                                <FontAwesomeIcon
                                  className="sh-icon arrow_down"
                                  icon="angle-down"
                                />
                              </div>
                            </div>
                            <div className="filter-collapse">
                              <div className="collapse-body collapse-list">
                                <div className="filter-list">
                                  <ul className="nav facets">
                                    {brands.map(function (brand, index) {
                                      return (
                                        <li
                                          className="facet active"
                                          key={index}
                                        >
                                          <div className="filter-item">
                                            <div className="custom-checkbox">
                                              <label>
                                                <input
                                                  type="checkbox"
                                                  name="brand"
                                                  className="checkbox"
                                                  data-facet="taxonomies.attr.Brand"
                                                  value={brand.name}
                                                  checked={checkBrand(brand)}
                                                  onChange={handleChange}
                                                />
                                                <i className="sh sh-checkbox"></i>
                                                <span className="item-name">
                                                  {brand.name}
                                                </span>
                                                <span className="item-count">
                                                  {brand.product_count}
                                                </span>
                                              </label>
                                            </div>
                                          </div>
                                        </li>
                                      );
                                    })}
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="filter-type">
                          <div className="filter-section">
                            <div className="filter-name filter-accordion">
                              <div className="filter-header">
                                <span className="cat-name"> Price </span>
                                <FontAwesomeIcon
                                  className="sh-icon arrow_down"
                                  icon="angle-down"
                                />
                              </div>
                            </div>
                            <div className="filter-collapse">
                              <div className="collapse-body">
                                <div
                                  className="range-slider"
                                  style={{ margin: "20px" }}
                                >
                                  <InputRange
                                    maxValue={priceRange.max}
                                    minValue={priceRange.min}
                                    formatLabel={(value) => `NPR ${value}`}
                                    onChange={(value) =>
                                      setPriceRangeChange(value)
                                    }
                                    onChangeComplete={(value) =>
                                      handlePriceChange(value)
                                    }
                                    value={priceRangeChange}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <AddSlider sliders={sliders} />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="search-results">
                  <div className="grid-view">
                    <div className="clearfix search-header">
                      <h3 style={{ marginLeft: "20px" }}>{layout.title}</h3>
                      <div className="search-text" id="stats">
                        Showing {productPaginate.total} Results
                      </div>
                      <div className="sort-wrp">
                        <span className="sort-by">Sort by</span>
                        <section className="sort-container">
                          <div className="selectric-wrapper selectric-sort">
                            <select
                              name="sort"
                              title="sort"
                              className="sort-by-select"
                              value={fetchParams().sort}
                              onChange={handleSortBy}
                            >
                              <option value=""> - </option>
                              <option value="new">New In</option>
                              <option value="low-to-high">Lowest Price</option>
                              <option value="high-to-low">Highest Price</option>
                            </select>

                            <div className="selectric-items" tabIndex="-1">
                              <div className="selectric-scroll">
                                <ul>
                                  <li data-index="0" className="selected">
                                    Most Relevant
                                  </li>
                                  <li data-index="1" className="">
                                    Lowest Price
                                  </li>
                                  <li data-index="2" className="last">
                                    Highest Price
                                  </li>
                                </ul>
                              </div>
                            </div>

                            <input className="selectric-input" tabIndex="0" />
                          </div>
                        </section>
                      </div>
                      <div id="refinement-tags" className="clearfix"></div>
                      <div className="product-scroller js-product-list">
                        <div className="product-container">
                          <div
                            className="product-items row reset-margin"
                            id="hits"
                          >
                            <InfiniteScroll
                              pageStart={0}
                              loadMore={() => loadMore()}
                              hasMore={hasMoreItems}
                              loader={
                                <div style={{ alignContent: "center" }} key={0}>
                                  <img
                                    src={LoadingImage}
                                    style={{
                                      height: "150px",
                                      display: "block",
                                      marginLeft: "auto",
                                      marginRight: "auto",
                                    }}
                                  />
                                </div>
                              }
                            >
                              {products.map(function (product, index) {
                                return (
                                  <div
                                    className="slide col-md-3 reset-padding fadeInUp"
                                    key={index}
                                  >
                                    <ListProduct product={product} />
                                  </div>
                                );
                              })}
                            </InfiniteScroll>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </React.Fragment>
  );
};

export default withRouter(LayoutViewAll);
